// import Cookies from 'js-cookie'

const accountKey = "login-account";
const originKey = "Authorization";
// 由于iframe登录时chrome无法写入cookie，使用localStorage存储信息；
export function getAccount() {
    return localStorage.getItem(accountKey);
}

export function setAccount(account: string) {
    localStorage.setItem(accountKey, account);
}

export function removeAccount() {
    localStorage.removeItem(accountKey);
}

export function getOrigin() {
    return localStorage.getItem(originKey);
}

export function setOrigin(token: string) {
    localStorage.setItem(originKey, token);
}

export function removeOrigin() {
    localStorage.removeItem(originKey);
}
