import { defineStore } from "pinia";
import { LoginApi } from "@/api/auth";
import { removeOrigin, setAccount } from "@/utils/auth";
interface LoginInfo {
    password: string,
    username: string
}
export const useUserStore = defineStore("user", {
    state: () => ({
        _accountInfo: {},
    }),
    getters: {
        accountInfo: (state) => state._accountInfo,
    },
    actions: {
        setAccountInfo(info: LoginInfo) {
            this._accountInfo = { ...info };
            setAccount(JSON.stringify(info));
        },
        Logout() {
            return new Promise<void>((resolve, reject) => {
                LoginApi.logout()
                    .then(() => {
                        removeOrigin();
                        // localStorage.removeItem("bid")
                        location.href = "/";
                        resolve();
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },
    },
});
