import { post, get } from "@/service";
import type { HdLogin } from './auth.i'
export const LoginApi = {
  getUserInfo() {
    return get<HdLogin.UserInfo>('/auth/userInfo')
  },
  login(params: HdLogin.LoginParams) {
    return post<HdLogin.LoginData>('/auth/login', params)
  },
  logout() {
    return get('/auth/loginout')
  },
}