import Request from './request'
// import { ElMessage } from 'element-plus'
import type { AxiosResponse } from 'axios'
import type { RequestConfig } from './request/types'
interface PublicRequestConfig<T> extends RequestConfig {
  data?: T
}
interface PublicResponse<T> {
  statusCode: number
  desc: string
  result: T
}

const request = new Request({
  // baseURL: import.meta.env.BASE_URL,
  timeout: 60 * 1000,
  interceptors: {
    // 请求拦截器
    requestInterceptors: config => config,
    // 响应拦截器
    responseInterceptors: result => result,
  },
})

/**
 * @description: 函数的描述
 * @generic D 请求参数
 * @generic T 响应结构
 * @param {PublicRequestConfig} config 不管是GET还是POST请求都使用data
 * @returns {Promise}
 */
export const PublicRequest = <T = any>(config: PublicRequestConfig<D>) => {
  const { method = 'GET' } = config
  if (method === 'get' || method === 'GET') {
    config.params = config.data
  }
  return request.request<T>(config)
}
// 取消请求
export const cancelRequest = (url: string | string[]) => {
  return request.cancelRequest(url)
}
// 取消全部请求
export const cancelAllRequest = () => {
  return request.cancelAllRequest()
}
export const get = <T = any>(url: string, data?: any) => {
  return PublicRequest<T>({ method: 'GET', url, data })
}
export const post = <T = any>(url: string, data?: any) => {
  return PublicRequest<T>({ method: 'POST', url, data })
}
export const put = <T = any>(url: string, data?: any) => {
  return PublicRequest<T>({ method: 'PUT', url, data })
}
export const del = <T = any>(url: string, data?: any) => {
  return PublicRequest<T>({ method: 'DELETE', url, data })
}

// export default PublicRequest
