import { defineStore } from "pinia";
import type { HdControls } from '@/api/controls.i'
export const useEquipStore = defineStore("equip", {
    state: () => ({
        selectEquip: JSON.parse(localStorage.getItem('selectEquip') || '{}') ? JSON.parse(localStorage.getItem('selectEquip') || '{}') : {},
    }),
    actions: {
        setSelectEquip(info: HdControls.CollectAttrValue) {
            this.selectEquip = info;
            localStorage.setItem('selectEquip', JSON.stringify(info))
        },
        removeSelectEauip() {
            localStorage.removeItem('selectEquip')
        }
    },
});
