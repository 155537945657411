<template>
  <van-tabbar v-model="activeTab">
    <template v-for="item in tabbar.list" :key="item.name">
      <template v-if="!item.slot">
        <van-tabbar-item
          :name="item.name"
          :to="`/${item.name}`"
          :icon="item.icon"
          >{{ item.title }}
        </van-tabbar-item>
      </template>
      <template v-else>
        <van-tabbar-item :name="item.name" :to="`/${item.name}`">
          <span>{{ item.title }}</span>
          <template #icon="props">
            <img
              :src="
                activeTab.includes(item.name)
                  ? item.activeImg
                  : item.unActiveImg
              "
            />
          </template>
        </van-tabbar-item>
      </template>
    </template>
  </van-tabbar>
</template>

<script setup lang="ts">
import { useRouter } from 'vue-router'
import { ref, reactive } from 'vue'
//引入本地图片
import LogoImg from '@/assets/logo.png'
const $router = useRouter()
// const props = defineProps({
//   activeTab: {
//     type: String,
//     default: "building",
//   },
// });
const activeTab = ref('building')
interface TabbarList {
  title: string
  icon: string
  name: string
  slot?: any
  activeImg?: string
  unActiveImg?: string
}
interface Tabbar {
  list: TabbarList[]
}
let tabbar = reactive<Tabbar>({
  list: [
    { title: '楼宇', icon: 'home-o', name: 'building' },
    { title: '我的', icon: 'user-o', name: 'me' }
  ]
})
</script>

<style lang="less" scoped>
.van-tabbar {
  .van-tabbar-item {
    .van-tabbar-item__icon {
      img {
        width: 30px;
      }
    }
  }
}
</style>
