import { createRouter, createWebHashHistory } from "vue-router";
/*浏览器上面进度条*/
import NProgress from "nprogress";
import "nprogress/nprogress.css";
import { getOrigin, removeOrigin, setOrigin } from "@/utils/auth";
import Layout from "@/views/layout/index.vue";
import type { RouteRecordRaw } from 'vue-router'
import { getQueryObject } from "@/utils/function";
NProgress.configure({
    showSpinner: false, // 是否显示加载ico
});

const routes: RouteRecordRaw[] = [
    {
        path: "/",
        redirect: "/building",
    },
    {
        path: "/index",
        redirect: "/building",
    },
    {
        path: "/building",
        component: Layout,
        redirect: "/building/building/0",
        children: [
            {
                path: "buildings",
                meta: {
                    title: "选择楼宇",
                },
                component: () => import("@/views/buildingList/index.vue"),
            },
            {
                path: "building/:bid",
                meta: {
                    title: "设备列表",
                },
                component: () => import("@/views/building/index.vue"),
            },
            {
                path: "equip/:bid/:equipId/:buildName",
                meta: {
                    title: "设备详情",
                },
                component: () => import("@/views/equip/index.vue"),
            },
        ],
    },

    {
        path: "/me",
        component: Layout,
        redirect: "/me/info",
        children: [
            {
                path: "info",
                meta: {
                    title: "我的",
                },
                component: () => import("@/views/me/Index.vue"),
            },
            {
                path: "resetPassword",
                meta: {
                    title: "修改密码",
                },
                component: () => import("@/views/me/ResetPassword.vue"),
            },
        ],
    },

    {
        path: "/login",
        component: () => import("@/views/login/Index.vue"),
        meta: {
            title: "登录",
        },
    },
    {
        path: "/wxLogin",
        component: () => import("@/views/login/WxLogin.vue"),
    },
    {
        path: "/customize",
        component: () => import("@/views/customize/Index.vue"),
    },
    {
        path: "/demo",
        component: () => import("@/views/demo/Index.vue"),
        redirect: "/demo/demo",
        children: [
            {
                path: "demo",
                component: () => import("@/views/demo/Demo.vue"),
            },
            {
                path: "list",
                meta: {
                    title: "分页列表",
                },
                component: () => import("@/views/demo/List.vue"),
            },
            {
                path: "amap",
                meta: {
                    title: "高德地图",
                },
                component: () => import("@/views/demo/Amap.vue"),
            },
            {
                path: "ant",
                component: () => import("@/views/demo/Ant.vue"),
            },
            {
                path: "pdf",
                component: () => import("@/views/demo/Pdf.vue"),
            },
        ],
    },
    {
        path: "/:pathMatch(.*)*",
        name: "404",
        redirect: "/",
    },
    {
        path: "/paramHistory",
        name: "paramhistory",
        component: () => import("@/views/paramHistory/index.vue"),
        meta: {
            title: "历史趋势",
        },
    },
];

const router = createRouter({
    history: createWebHashHistory(),
    routes,
});

router.beforeEach((to, from, next) => {
    let title = import.meta.env.VITE_APP_TITLE;
    const obj = getQueryObject();
    for (let key in obj) {
        if (key.includes("token") && obj[key]) {
            setOrigin(obj[key]);
        }
    }
    //如果设置标题就显示，没设置使用默认
    // if (null != to.meta && null != to.meta.title && "" !== to.meta.title) {
    //     title = to.meta.title;
    // }
    if (/ip(hone|od|ad)/i.test(navigator.userAgent)) {
        setTimeout(function () {
            document.title = title;
            let _iframe = document.createElement("iframe");
            _iframe.style.display = "none";
            _iframe.onload = function () {
                setTimeout(function () {
                    document.body.removeChild(_iframe);
                }, 0);
            };
            document.body.appendChild(_iframe);
        }, 0);
    } else {
        document.title = title;
    }

    const hasAccount = getOrigin();
    if (!hasAccount && to.path !== "/login") {
        next("/login");
        return;
    }
    //浏览器上方显示进度条
    NProgress.start();
    //正常放行
    next();
});

router.afterEach((transition) => {
    //关闭浏览器上方的进度条
    NProgress.done();
});

/**
 * 捕获错误
 */
router.onError((error) => {
    console.warn("路由router错误，error:", error);
    alert("路由错误error:" + error);
});

export default router;
